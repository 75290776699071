import { handleSignout } from "actions"
import { handleSignin } from "actions"
import useLogin from "actionsFeatures/actionsLogin"
import { routes } from "App"
import { LocalStorageManager } from "helper/localStorage"
import { useDispatch } from "react-redux"
import LoginService from "services/loginService"

export default function useAutoLogin() {
  const dispatch = useDispatch()
  const { checkErrorLogin } = useLogin()

  async function handleLoginByToken() {
    const searchParams = new URLSearchParams(window.location.search)
    const token = searchParams.get('token')
    const path = searchParams.get('path')
    const isWebviewApp = searchParams.get('isWebviewApp')

    dispatch(handleSignout())

    if (isWebviewApp === '1' || window.location.href?.includes("webview")) {
      LocalStorageManager.setItem("isWebviewApp", isWebviewApp)
    }

    const tokenDecoded = token ? atob(token) : null
    const pathDecoded = path ? atob(path) : routes.home.path

    if (tokenDecoded) {
      const response = await LoginService.loginByToken({ token: tokenDecoded })
      if (response.isSuccess) {
        dispatch(handleSignin({ ...response.data }))
      } else {
        checkErrorLogin(response.error)
        await new Promise(resolve => setTimeout(resolve, 2500));
      }

      await new Promise(resolve => setTimeout(resolve, 200));
    }

    window.location.href = pathDecoded
  }

  return {
    handleLoginByToken
  }
}